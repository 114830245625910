<script>
import GalleryForm from "@/views/menu/gallery/galleryForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    galleryService: "galleryService",
  }
})
export default class GalleryDetail extends mixins(GalleryForm) {
  formId = "detail-gallery-form";

  get decoratedElements() {
    return this.allReadonlyFields;
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle () {
    return this.translations.pageTitles.galleries_detail;
  }

  async afterCreate () {
    this.model = await this.galleryService.read(this.$route.params.id);
    this.isReady = true;
  }
}
</script>